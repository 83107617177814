import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { NextButton, PrevButton } from '../Components/InputFields/BrancherButton';
import { BrancherDivider, BrancherThickDivider } from '../Components/General/BrancherDivider';
import { Text } from '../Components/General/Text';
import { IQualifyingFormState } from './QualifyingForm';
import { IStoreTypes } from '../store/storeTypes';

export const AboutApplication = (props: IQualifyingFormState) => {
  const { step, setStep } = props;
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);

  const controlNextStep = () => {
    // dispatch(ClearGeneralUserResponseData());
    setStep(step + 1);
  };

  return (
    <Grid container direction="column" justify="center">
      <Grid>
        <PrevButton variant="text" color="primary" onClick={() => setStep(step - 1)}>
          Back
        </PrevButton>
      </Grid>
      <BrancherDivider />
      <BrancherThickDivider marginTop={40} marginBottom={20} />
      <Text variant="xl" marginBottom={20}>
        About this application
      </Text>
      <Grid item>
        <Text variant="sm">
          We are excited for you to start {roleLabels.programStyle}.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginTop={20} marginBottom={10}>
          It's important we ask the following questions to understand your preferences and what you are looking for.
        </Text>
        <Text variant="sm" display="inline" marginTop={20} marginBottom={10}>
          Because everyone is unique
        </Text>
        <Text variant="lg" display="inline" marginTop={20} marginBottom={10} marginLeft={8}>
          &#128522;
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={10} >
          It should take about 10 - 15 minutes.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="xs" marginTop={10} marginBottom={30}>
          The data collected in this survey will be used for pairing and research purposes. All results are confidential and will be stored safely and securely.
        </Text>
      </Grid>
      <Grid item>
        <NextButton onClick={controlNextStep} fullWidth>
          Next
        </NextButton>
      </Grid>
    </Grid>
  );
};
