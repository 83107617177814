import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GetIllustrationLink } from '../../utils/LinkUtils';

export const personalityMarkerWidth = 44;

const useStyles = makeStyles({
  image: {
    marginBottom: -12,
    width: personalityMarkerWidth,
    height: 50,
  },
});

export const PersonalityMarker: React.FC = () => {
  const styles = useStyles();
  return (
    <img src={GetIllustrationLink('marker.svg')} alt="your attribute marker" className={styles.image} />
  );
};


export const PartnerPersonalityMarker: React.FC = () => {
  const styles = useStyles();
  return (
    <img src={GetIllustrationLink('partner-marker.svg')} alt="partner attribute marker" className={styles.image} />
  );
};
