import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Colors } from '../../consts/colors';
import { Text } from '../../Components/General/Text';
import { IValuesPersonalityAttributeInsights, TAllPersonality } from '../../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { PartnerPersonalityMarker, PersonalityMarker, personalityMarkerWidth } from './PersonalityMarkers';

const markerDiameter = personalityMarkerWidth / 2;
const useStyles = makeStyles({
  scale: {
    backgroundColor: Colors.scaleGrey,
    height: 3,
    width: '100%',
  },
  scaleSlider: {
    marginTop: -3,
    width: (props: { width: number }) => `${props.width}%`,
    marginLeft: (props: { min: number }) => `${props.min}%`,
    backgroundColor: Colors.purple,
    height: 3,
  },
  yourMarker: {
    position: (props: IScaleMarker) => props.yourValue >= props?.partnerValue ? 'relative' : 'absolute',
    left: (props: IScaleMarker) => props.yourValue < props?.partnerValue ? `calc(${props.yourValue}% - ${markerDiameter}px)` : 'unset',
    marginLeft: (props: IScaleMarker) => props.yourValue >= props?.partnerValue ? `calc(${props.yourValue}% - ${markerDiameter}px)` : 'unset',
  },
  partnerMarker: {
    position: (props: IScaleMarker) => props.yourValue < props?.partnerValue ? 'relative' : 'absolute',
    left: (props: IScaleMarker) => props.yourValue >= props?.partnerValue ?`calc(${props.partnerValue}% - ${markerDiameter}px)` : 'unset',
    marginLeft: (props: IScaleMarker) => props.yourValue < props?.partnerValue ?`calc(${props.partnerValue}% - ${markerDiameter}px)` : 'unset',
  },
});

interface IPersonalityScaler {
  personalityScales: IValuesPersonalityAttributeInsights[];
  isMatch: boolean;
}

export const PersonalityScaler: React.FC<IPersonalityScaler> = (props) => {
  const { personalityScales, isMatch } = props;
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const styles = useStyles();

  const personalityScaleMap: {[x in TAllPersonality]: {
    start: string;
    end: string;
  }} = {
    [TAllPersonality.AGREEABLENESS]: {
      start: 'Independent',
      end: 'Harmonious',
    },
    [TAllPersonality.EXTRAVERSION]: {
      start: 'Introspective',
      end: 'Social',
    },
    [TAllPersonality.CONSCIENTIOUSNESS]: {
      start: 'Delegative',
      end: 'Conscientious',
    },
    [TAllPersonality.OPENNESS]: {
      start: 'Pragmatic',
      end: 'Explorative',
    },
    [TAllPersonality.HUMILITY]: {
      start: 'Opportunistic',
      end: 'Principled',
    },
    [TAllPersonality.EMOTIONALITY]: {
      start: 'Stoic',
      end: 'Emotional',
    },
  };

  return (
    <Grid container spacing={4}>
      {personalityScales?.map((personality) => (
        <Grid container item justify="center" key={personality.attributeName}>
          <Grid container item xs={12} alignItems="flex-end" spacing={2}>
            <Grid item xs={3}>
              <Text variant="xxs" align="right" fontWeight={500} marginBottom={-6}>
                {personalityScaleMap[personality.attributeName].start}
              </Text>
            </Grid>

            <Grid item xs={12} sm={6} container>
              <ScaleMarker
                yourValue={isMentee ? personality.menteeValue : personality.mentorValue}
                partnerValue={isMatch ? (isMentee ? personality.mentorValue : personality.menteeValue) : null}
              />
              <Box className={styles.scale} />
              {isMatch && (
                <ScaleSlider
                  max={personality.mentorValue > personality.menteeValue ? personality.mentorValue : personality.menteeValue}
                  min={personality.mentorValue > personality.menteeValue ? personality.menteeValue : personality.mentorValue}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <Text variant="xxs" align="left" fontWeight={500} marginBottom={-6}>
                {personalityScaleMap[personality.attributeName].end}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

interface IScaleSlider {
  max: number;
  min: number;
}

const ScaleSlider: React.FC<IScaleSlider> = ( { max, min } ) => {
  const width = max - min === 0 ? 2 : max - min;
  const styles = useStyles({ width, min });

  return (
    <Box className={styles.scaleSlider} />
  );
};

interface IScaleMarker {
  yourValue: number;
  partnerValue?: number;
}

const ScaleMarker: React.FC<IScaleMarker> = ( { yourValue, partnerValue } ) => {
  const adjustmentForVisibility = 2;
  const adjustYourValueVisibility = yourValue === partnerValue ? (yourValue + adjustmentForVisibility) : yourValue;
  const styles = useStyles({ yourValue: adjustYourValueVisibility, partnerValue });

  const YourValue = () => (
    <Box className={styles.yourMarker}>
      <PersonalityMarker />
    </Box>
  );

  const PartnerValue = () => !!partnerValue &&
    <Box className={styles.partnerMarker}>
      <PartnerPersonalityMarker />
    </Box>;

  return (
    <Grid container item xs={12}>
      <Box width="100%" position="relative">
        {yourValue >= partnerValue ? (
          <>
            <PartnerValue />
            <YourValue />
          </>
        ) : (
          <>
            <YourValue />
            <PartnerValue />
          </>
        )}
      </Box>
    </Grid>
  );
};
